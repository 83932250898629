<template>
  <div class="container">
    <p>
      江西智慧云测安全检测中心股份有限公司是北京智慧云测设备技术有限公司（DPLSLab）在江西省鹰潭市高新技术开发区设立的子公司，已被国家密码管理局列入最新的《商用密码应用安全性评估试点机构目录》，可面向全国开展商用密码应用安全性评估工作。
    </p>
    <p>
      同时，拥有中国合格评定国家认可委员会CNAS认可授权实验室资质、ISO9001质量管理体系认证资质等，是一家在国际、国内的信息安全行业享有较高知名度和声誉的全流程服务的综合性安全实验室；公司技术团队成员由博士、硕士以及归国留学人员等组成，核心技术人员均获得过国家部委级别的科技特等奖、一等奖，参与过国家标准和行业标准等的制修订工作；在信息安全、物联网、人工智能等领域有着丰富的行业经验；可利用给检测产品注进特定宽度的电磁脉冲，检测产品电磁辐射、功耗、电流波等数据，实现智能手机、智能燃气表及水表、智能门锁、金融终端等安全检测。
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 840px;
  width: 1170px;
  background-color: #fff;
  margin: 20px auto;
  padding: 80px 20px;
  p {
    color: #727272;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 26px;
    margin: 0 0 20px;
    word-wrap: break-word;
    text-align: left;
    text-indent: 2em;
  }
}
</style>
